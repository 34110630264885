import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { updatePromoShopItem } from '../../../apis/atlantis.api';

const ShopItemDetailsForm = ({ shopItem, onChange, token, handleClose }) => {
  const [formValues, setFormValues] = useState(shopItem);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormValues(shopItem);
  }, [shopItem]);

  const handleChange = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    onChange({ ...formValues, [name]: value });
  };

  const handleSubmit = async formValues => {
    setLoading(true);
    const itemDetails = {
      cost: formValues.cost,
      currency: shopItem.currency,
      description: formValues.description,
      imageUrl: formValues.imageUrl,
      name: formValues.name,
      ogCost: formValues.ogCost,
      disabled: formValues.disabled,
    };

    try {
      await updatePromoShopItem(shopItem.promoId, shopItem.itemId, itemDetails, token);
      handleClose();
    } catch (error) {
      console.error('Failed to update shop item:', error);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to render form fields
  const renderFormField = (id, label, type = 'text', disabled = false) => (
    <div className="mb-4">
      <label
        htmlFor={id}
        className="form-label text-muted fw-medium mb-2"
        style={{ fontSize: '0.875rem' }}
      >
        {label}
      </label>
      <input
        type={type}
        className="form-control form-control-lg shadow-none border-light-subtle rounded-3"
        id={id}
        name={id}
        value={formValues[id] || ''}
        onChange={handleChange}
        disabled={disabled}
        style={{
          padding: '0.75rem 1rem',
          fontSize: '1rem',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        }}
      />
    </div>
  );

  // Helper function to render image fields
  const renderImageField = (id, label) => (
    <div className="mb-4">
      <label
        htmlFor={id}
        className="form-label text-muted fw-medium mb-2"
        style={{ fontSize: '0.875rem' }}
      >
        {label}
      </label>
      <input
        type="text"
        className="form-control form-control-lg shadow-none border-light-subtle rounded-3"
        id={id}
        name={id}
        value={formValues[id] || ''}
        onChange={handleChange}
        style={{
          padding: '0.75rem 1rem',
          fontSize: '1rem',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        }}
      />
      {formValues[id] && (
        <div className="mt-3 p-3 bg-light rounded-3 text-center">
          <img
            src={formValues[id]}
            alt={label}
            className="img-fluid rounded-3 shadow-sm"
            style={{ maxHeight: '200px', objectFit: 'contain' }}
          />
        </div>
      )}
    </div>
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        handleSubmit(formValues);
      }}
      className="bg-white p-4 rounded-4 shadow-sm"
    >
      <h5 className="mb-4 fw-semibold text-dark">Item Details</h5>

      <div className="row g-4">
        <div className="col-md-6">
          {renderFormField('name', 'Name')}

          <div className="mb-4">
            <label
              htmlFor="description"
              className="form-label text-muted fw-medium mb-2"
              style={{ fontSize: '0.875rem' }}
            >
              Description
            </label>
            <textarea
              className="form-control shadow-none border-light-subtle rounded-3"
              id="description"
              name="description"
              value={formValues.description || ''}
              onChange={handleChange}
              rows="4"
              style={{
                padding: '0.75rem 1rem',
                fontSize: '1rem',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
              }}
            />
          </div>

          <div className="row g-3">
            <div className="col-md-6">{renderFormField('ogCost', 'Original Cost', 'number')}</div>
            <div className="col-md-6">{renderFormField('cost', 'Discount Cost', 'number')}</div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="ungiftable"
              className="form-label text-muted fw-medium d-flex align-items-center mb-2"
              style={{ fontSize: '0.875rem' }}
            >
              Direct Item (No Rewards)
              <span
                className="badge bg-success-subtle text-success ms-2 rounded-pill"
                style={{ fontSize: '0.7rem' }}
              >
                Read Only
              </span>
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="ungiftable"
                id="ungiftable"
                disabled={true}
                checked={shopItem.ungiftable === true}
                style={{ width: '2.5em', height: '1.25em' }}
              />
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="disabled"
              className="form-label text-muted fw-medium d-flex align-items-center mb-2"
              style={{ fontSize: '0.875rem' }}
            >
              Item Status
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                name="disabled"
                id="disabled"
                checked={formValues.disabled === true}
                onChange={e => {
                  const newValues = { ...formValues, disabled: e.target.checked };
                  setFormValues(newValues);
                  onChange(newValues);
                }}
                style={{ width: '2.5em', height: '1.25em' }}
              />
              <label className="form-check-label" htmlFor="disabled">
                &nbsp;Disabled (Not Purchasable)
              </label>
            </div>
            <small className="form-text text-muted" style={{ fontSize: '0.75rem' }}>
              Toggle ON to prevent users from purchasing this item. Toggle OFF to make item
              available.
            </small>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card border-0 shadow-sm rounded-4 mb-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Item Image</h6>
              {renderImageField('imageUrl', 'Image URL')}
            </div>
          </div>

          <div className="card border-0 shadow-sm rounded-4">
            <div className="card-body">
              <h6 className="card-subtitle mb-3 text-muted fw-medium">Inventory Settings</h6>
              <div className="row g-3">
                <div className="col-md-6">
                  {renderFormField('upperLimit', 'Inventory', 'text', true)}
                </div>
                <div className="col-md-6">
                  {renderFormField('dailyPurchaseLimit', 'Daily Purchase Limit', 'text', true)}
                </div>
              </div>
              <div className="text-muted mt-2" style={{ fontSize: '0.8rem' }}>
                <i className="bi bi-info-circle me-1"></i>
                Inventory settings can only be modified during item creation.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end mt-4 pt-3 border-top">
        <button
          type="button"
          className="btn btn-light rounded-3 px-4 py-2"
          onClick={handleClose}
          style={{ fontSize: '0.95rem' }}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary rounded-3 px-4 py-2 ms-3"
          disabled={loading}
          style={{
            fontSize: '0.95rem',
            backgroundColor: '#6366f1',
            borderColor: '#6366f1',
            boxShadow: '0 2px 4px rgba(99, 102, 241, 0.25)',
          }}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" role="status" className="me-2" />
              <span>Saving...</span>
            </>
          ) : (
            'Save Changes'
          )}
        </button>
      </div>
    </form>
  );
};

export default ShopItemDetailsForm;
