import { DateTime } from 'luxon';
import { createContext, useContext, useState } from 'react';
import { signMessage } from '../rpc/signMessage.rpc';
import { authorize, authorizeViaTx, getNonce } from '../apis/auth.api';
import { authTransfer } from '../rpc/authTransfer.rpc';
import { saveState } from '../utils/localStorage';
import { useWallet } from '@solana/wallet-adapter-react';
const t = {
  payload: {
    idToken:
      'eyJraWQiOiJFSG5ySG5FTnp6MEhUQjNPblV1STBwQjEzZ2xvdittNWw4cHArVXVWaUx3PSIsImFsZyI6IlJTMjU2In0.eyJvcmlnaW5fanRpIjoiYjM3MmRmNmQtMWRiYS00NDNhLWIzMTUtNzI1MzAyNDI3ZDcxIiwic3ViIjoiZGI3ZDVlMTctNTAwNS00NWMwLWI2MjItZWIwMTVmMzViNTFmIiwiYXVkIjoiMWVmdGU0dDR2dG51Yzh2NjVwZ2JocTR1ZTciLCJldmVudF9pZCI6ImEyYThhNWM3LTZmN2YtNDc0Zi04ZjAxLTQ5MzRhMDk3NGViOSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNzMzOTMxNDE4LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0yLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMl9VelFSU1hOVm0iLCJjb2duaXRvOnVzZXJuYW1lIjoidmVubTZXTWFEckxGWEdrdkVaMnN3Q2ZaY3k1UmRvaDFEVGh2VUVuM2FlaiIsImV4cCI6MTczMzk0OTQxOCwiaWF0IjoxNzMzOTMxNDE4LCJqdGkiOiI4ODQ4OTA3My1lOTc5LTQzZDktODE0My1jNzliM2UyYTY1ZjAifQ.UHMFM4NgM195zQM-PUVtHm8BcuYpQRG7Wy7ckQIaUA9Q892HWhY2E-na4ckMJr3qRAwnPv9NaAQImSf0GuTeJXOMclkufWeZK56QoYFBJFohM4z0iEtDGyHQcZgfNegmABf4JhsPKAlAlX7w7huD9muCLjT7RotiqKq1u_Q8qjpCZim-6aKMOTeaEfb1K_L69GENr6_Osn2Dxdhxiq_eR8WBnmPoU6srYMCTPgZW388gYJud953CDHfw8abCfxUguG2Z4tQ8VlmsdB7Y6eULYEWshCG2X1C496vOU0s_lIDMdJ9UMLXeDkrwP1urqSjtdYcimAqgDEcr7d-mU5vuuQ',
    exp: 1733949418,
    username: 'venm6WMaDrLFXGkvEZ2swCfZcy5Rdoh1DThvUEn3aej',
    status: 'VERIFIED',
  },
};
const getHash = async nonce => {
  const prefixString = `I am signing my one-time nonce: ${nonce}`;
  const message = new TextEncoder().encode(prefixString);
  return message;
};

const AuthorizationContext = createContext({
  auth: null,
  signIn() {},
  signInViaLedger() {},
  signOut() {},
  signNonce() {},
  isExpired() {},
});

export const useAuth = () => {
  return useContext(AuthorizationContext);
};

const AuthorizationProvider = props => {
  const [auth, setAuth] = useState(t.payload);
  const wallet = useWallet();
  const handleSaveAuth = data => {
    saveState(data);
    setAuth(data);
  };

  const signIn = async (walletId = wallet?.publicKey?.toString(), referral) => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== walletId) {
      if (!wallet.signMessage || !wallet.publicKey) {
        alert('Cannot sign message, please try again or try another wallet!');
        return;
      }

      const { nonce } = await getNonce(walletId, referral);
      const signature = await wallet.signMessage(await getHash(nonce));
      const authorization = await authorize(walletId, signature);
      handleSaveAuth(authorization);
      return authorization;
    }

    return auth;
  };

  const signInViaLedger = async wallet => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    if (!auth || expired || auth?.username !== wallet?.publicKey?.toString()) {
      const tx = await authTransfer(wallet);
      const authorization = await authorizeViaTx(wallet?.publicKey?.toString(), tx);
      handleSaveAuth(authorization);
      return authorization;
    }

    return auth;
  };

  const signOut = () => handleSaveAuth(null);

  const signNonce = async () => {
    const walletId = auth?.username;
    const { nonce } = await getNonce(walletId, '');
    const { signature } = await signMessage(nonce);
    return signature;
  };

  const isExpired = () => {
    const expired = DateTime.fromSeconds(auth?.exp ?? 0) < DateTime.utc().plus({ minutes: 150 });
    return !auth || expired;
  };

  return (
    <div>
      <AuthorizationContext.Provider
        value={{ auth, signIn, signInViaLedger, signOut, signNonce, isExpired }}
      >
        {props.children}
      </AuthorizationContext.Provider>
    </div>
  );
};

export { AuthorizationContext, AuthorizationProvider };
