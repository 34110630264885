import { Form, Table, Button, Image, Spinner } from 'react-bootstrap';
import { RewardType } from '../../../utils/constants';
import React, { useState, useEffect } from 'react';
import { updateRewardsOfItem } from '../../../apis/atlantis.api';

export const DefaultImage = {
  SOL: 'https://i.imgur.com/l7Nq2b5.png',
  RFF: 'https://i.imgur.com/QyqpKeg.png',
  DOZER_COIN: 'https://imgur.com/m7K0Eto.png',
  USDC: 'https://i.imgur.com/Efa3LOR.png',
};

const ShopItemRewardsForm = ({ rewards, promoId, itemId, handleClose, token, fetchRewards }) => {
  const disableImageUrl = reward =>
    reward.rewardType === 'SOL' || reward.rewardType === 'RFF' || reward.rewardType === 'USDC';
  const [editedRewards, setEditedRewards] = useState(rewards);
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const totalProbability = editedRewards.reduce((acc, reward) => acc + Number(reward.pct), 0);

  useEffect(() => {
    setEditedRewards(rewards);
  }, [rewards]);

  const handleSubmit = async rewards => {
    setLoading(true);
    setSubmitSuccess(false);

    const mappedData = rewards.map(reward => {
      const data = {
        promoId: promoId,
        name: reward.name,
        imageUrl: reward.imageUrl,
        type: reward.rewardType,
        form: reward.formRequired,
        unlimited: reward.unlimited,
        pct: reward.pct,
        quantity: reward.quantity,
        dozerCoinAmount: reward.dozerCoinAmount,
      };
      if (!reward.unlimited) {
        data['quantity'] = +reward.quantity;
      }
      if (reward.rewardType === 'SOL' || reward.rewardType === 'RFF') {
        data['solAmount'] = reward.solAmount;
      }
      if (reward.rewardType === 'USDC') {
        data['usdcAmount'] = reward.usdcAmount;
      }
      if (reward.rewardType === 'DOZER_COIN') {
        data['dozerCoinAmount'] = reward.dozerCoinAmount;
      }

      return data;
    });
    const rewardsData = { rewards: mappedData };
    try {
      await updateRewardsOfItem(promoId, itemId, rewardsData, token);
      setSubmitSuccess(true);
      setTimeout(() => {
        handleClose();
        fetchRewards();
      }, 1500);
    } catch (e) {
      alert(e);
      setLoading(false);
      handleClose();
      fetchRewards();
    }

    setLoading(false);
  };

  const handleRewardChange = (index, key, value) => {
    const updatedRewards = [...editedRewards];
    if (key === 'solAmount' || key === 'pct') {
      value = parseFloat(value);
      if (isNaN(value)) {
        value = 0;
      }
      if (key === 'rewardType') {
        if (value === 'SOL' || value === 'RFF' || value === 'USDC') {
          updatedRewards[index]['imageUrl'] = DefaultImage[value];
        }
      }
    }
    updatedRewards[index][key] = value;
    setEditedRewards(updatedRewards);
  };

  const addReward = () => {
    const defaultRewardType = RewardType.SOL;
    const newReward = {
      imageUrl: DefaultImage[defaultRewardType],
      pct: 0,
      itemId: itemId,
      promoId: promoId,
      name: '',
      unlimited: true,
      formRequired: 'NONE',
      rewardType: defaultRewardType,
      solAmount: 0,
      usdcAmount: 0,
      dozerCoinAmount: 0,
    };
    setEditedRewards([...editedRewards, newReward]);
  };

  const removeReward = index => {
    const updatedRewards = editedRewards.filter((_, idx) => idx !== index);
    setEditedRewards(updatedRewards);
  };
  const pctSum = editedRewards.reduce((acc, reward) => acc + Number(reward.pct), 0);

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Image URL</th>
            <th>Image Preview</th>
            <th>Reward Type</th>
            <th>SOL Amount</th>
            <th>USDC Amount</th>
            <th>Dozer Coin Amount</th>
            <th>Probability</th>
            <th>Unlimited</th>
            <th>Quantity</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {editedRewards.map((reward, index) => (
            <tr key={index}>
              <td>
                <Form.Control
                  value={reward.name}
                  onChange={e => handleRewardChange(index, 'name', e.target.value)}
                  required
                />
              </td>
              <td>
                <Image src={reward.imageUrl} alt={reward.name} width={50} height={50} thumbnail />
              </td>
              <td>
                {disableImageUrl(reward) ? (
                  <></>
                ) : (
                  <Form.Control
                    type="text"
                    name="imageUrl"
                    value={reward.imageUrl}
                    onChange={e => handleRewardChange(index, 'imageUrl', e.target.value)}
                  />
                )}
              </td>
              <td>
                <Form.Control
                  as="select"
                  value={reward.rewardType}
                  onChange={e => handleRewardChange(index, 'rewardType', e.target.value)}
                >
                  {Object.values(RewardType).map(type => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Control>
              </td>
              <td>
                <Form.Control
                  value={reward.solAmount}
                  type="number"
                  onChange={e => handleRewardChange(index, 'solAmount', e.target.value)}
                  disabled={reward.rewardType === 'DOZER_COIN' || reward.rewardType === 'USDC'}
                />
              </td>
              <td>
                <Form.Control
                  value={reward.usdcAmount}
                  type="number"
                  onChange={e => handleRewardChange(index, 'usdcAmount', e.target.value)}
                  disabled={reward.rewardType !== 'USDC'}
                />
              </td>
              <td>
                <Form.Control
                  value={reward.dozerCoinAmount}
                  type="number"
                  onChange={e => handleRewardChange(index, 'dozerCoinAmount', e.target.value)}
                  disabled={reward.rewardType !== 'DOZER_COIN'}
                />
              </td>
              <td>
                <Form.Control
                  value={reward.pct}
                  type="number"
                  onChange={e => handleRewardChange(index, 'pct', e.target.value)}
                  required
                />
              </td>
              <td>
                <Form.Check
                  checked={reward.unlimited}
                  value={reward.unlimited}
                  type="checkbox"
                  onChange={e => handleRewardChange(index, 'unlimited', e.target.checked)}
                />
              </td>
              <td>
                <Form.Control
                  disabled={reward.unlimited}
                  value={reward.quantity}
                  type="number"
                  onChange={e => handleRewardChange(index, 'quantity', e.target.value)}
                />
              </td>
              <td>
                <Button variant="danger" onClick={() => removeReward(index)}>
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        <h6 className={`${Math.abs(pctSum - 1) > 0.001 ? 'text-danger' : ''}`}>
          Total Probability: {totalProbability.toFixed(3)}
        </h6>
      </div>
      <Button variant="primary" onClick={addReward}>
        Add Reward
      </Button>
      <div className="d-flex justify-content-end align-items-center">
        <button className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        {loading ? (
          <Spinner animation="border" role="status" className="mx-2">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : submitSuccess ? (
          <div className="text-success mx-2 d-flex align-items-center">
            <span>✓ Changes saved successfully!</span>
          </div>
        ) : (
          <button
            className="btn btn-primary mx-2"
            onClick={() => handleSubmit(editedRewards)}
            disabled={Math.abs(pctSum - 1) > 0.001}
          >
            Save Changes
          </button>
        )}
      </div>
    </div>
  );
};

export default ShopItemRewardsForm;
