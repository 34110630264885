import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddShopItemForm from './AddShopItemForm';

const AddShopItemButton = ({ onAdd }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleAdd = shopItem => {
    onAdd(shopItem);
    handleClose();
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add Shop Item
      </Button>

      <Modal show={showModal} onHide={handleClose} size="xl">
        {/* <Modal.Header closeButton>
          <Modal.Title>Add Shop Item</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <AddShopItemForm onAdd={handleAdd} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddShopItemButton;
